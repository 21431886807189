import { format, parse } from "date-fns";
import {
	findGetParameter,
	setGetParameter,
	removeGetParameter,
} from "../common/utilities";

window.td_case_studies = function () {
	return {
		posts: [],
		categories: [],
		currentCategory: 0,
		currentPageNumber: 1,
		isLoading: true,
		numberOfPosts: 0,
		perPage: 9,
		totalPages: 0,
		fetchPosts() {
			this.posts = [];
			this.isLoading = true;

			let baseURL = "/wp-json/wp/v2/case-study?per_page=" + this.perPage;

			let currentCategory = findGetParameter("project_category");
			let parsedCategory = parseInt(currentCategory);

			if (!isNaN(parsedCategory)) {
				this.currentCategory = parsedCategory;

				if (parsedCategory) {
					baseURL += "&project_category=" + currentCategory;
				}
			}

			baseURL += "&page=" + this.currentPageNumber;
			baseURL += "&_embed"; // Add media to response

			return fetch(baseURL)
				.then((response) => {
					let totalPages = parseInt(
						response.headers.get("X-WP-TotalPages")
					);

					if (totalPages || totalPages === 0) {
						this.totalPages = parseInt(
							response.headers.get("X-WP-TotalPages")
						);
					}

					return response.json();
				})
				.then((posts) => {
					this.posts = this.formatPosts(posts);
					this.isLoading = false;
				});
		},
		formatPosts(posts) {
			for (let post of posts) {
				let date = new Date(post.date);
				post.formattedDate = format(date, "do LLLL, yyyy");

				if (post._embedded["wp:featuredmedia"]) {
					let image = post._embedded["wp:featuredmedia"]["0"];
					let imageSizes = image["media_details"];

					post.featuredImageURL = image.source_url;

					if (
						imageSizes &&
						imageSizes["sizes"] &&
						imageSizes["sizes"]["large"]
					) {
						post.featuredImageURL =
							imageSizes["sizes"]["large"].source_url;
					}
				}

				// Add category name for case studies
				if (post._embedded["wp:term"]) {
					let categories = post._embedded["wp:term"].find(
						(terms) => terms[0].taxonomy === "project_category"
					);
					if (categories && categories.length) {
						post.category = categories[0].name; // Get the first project category name
					}
				} else {
					post.category = ""; // Fallback if no category is found
				}
			}

			return posts;
		},
		nextPage() {
			if (this.currentPageNumber < this.totalPages) {
				this.currentPageNumber++;
			}

			this.$nextTick(() => this.scrollToTop());

			this.fetchPosts();
		},

		previousPage() {
			if (this.currentPageNumber > 1) {
				this.currentPageNumber--;
			}

			this.$nextTick(() => this.scrollToTop());

			this.fetchPosts();
		},

		showCategory(id) {
			this.currentPageNumber = 1;
			setGetParameter("project_category", id);

			this.fetchPosts();
		},

		scrollToTop() {
			$(document).scrollTop(0);
		},
	};
};
